/* GENERAL */


body{
    font-family: 'opensans', sans-serif;
}

.hr{
    border-top: 1px solid gray;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.underline{
    position: relative;
    margin-bottom: 2rem;
}
.underline::after{
    content: '';
    white-space: pre;
    position: absolute;
    bottom: -0.5rem;
    height: 0.5rem;
    left: 0rem;
    width: 4.5rem;
    background: $primary-color;
    
    /* optional animation */
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
}
.underline:hover::after{
    // width: 4rem;
}
.active_underline::after {
    content: '';
    white-space: pre;
    position: absolute;
    bottom: -0.5rem;
    height: 0.5rem;
    left: 0rem;
    width: 4.5rem;
    background: $primary-color;
}
.light {
    font-weight: 500;
}
.clickable {
    cursor: pointer;
}
.clickthrough {
    pointer-events: none;
}

/* HEADER + FOOTER */
header {
    margin-top: 0.9rem;
    margin-bottom: 3rem;
}
.middlefoot-left {
    display: flex;
    flex-direction: column;
}
#topmenu{
    background-color: $primary-color;
    font-size: 0.8rem;
    // margin-top: 0.9rem;
    // margin-bottom: 3rem;
    justify-content: flex-end;
}
#topmenu>li {
    // border-right: 1px solid #fff;
    >a{
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        padding: 15px 5px 15px 5px;
        &.active {
            color: rgba(255,255,255,0.4);
        }
    }
}
#topmenu>li:not(:last-child) a:after {
    content: '|';
    padding-left: 1rem;
    color: #fff;
}

.redbg {
    background-color: $primary-color;
}
// .menulonger {
//     margin-top: 0.9rem;
//     margin-bottom: 3rem;
// }
// #menuhelp{
//     margin-left: 8rem;
// }

.gradient-bg {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+47,c32a2a+47,ffffff+47,c32a2a+48,c32a2a+100 */
    background: rgb(255,255,255); /* Old browsers */
    background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 47%, rgba(195,42,42,1) 47%, rgba(255,255,255,1) 47%, rgba(195,42,42,1) 48%, rgba(195,42,42,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 47%,rgba(195,42,42,1) 47%,rgba(255,255,255,1) 47%,rgba(195,42,42,1) 48%,rgba(195,42,42,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 47%,rgba(195,42,42,1) 47%,rgba(255,255,255,1) 47%,rgba(195,42,42,1) 48%,rgba(195,42,42,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#c32a2a',GradientType=1 ); /* IE6-9 */
}

.mobilemenu{
    background-color: white;
}
.mobilemenuhelp{
    background-color: $primary-color;
    color: white;
    font-weight: 900;
    position: relative;
    margin-top: 0.7rem;
}
.menu-icon{
    position: absolute;
}
.menu-icon::after{
    width: 120%;
    height: 4px;
    top: 0.7rem;
    left: 3.5rem;
    box-shadow: 0px 8px 0 #fefefe, 0px 16px 0 #fefefe;
    -webkit-box-shadow: 0px 8px 0 #fefefe, 0px 16px 0 #fefefe;
}

.overfoot-left{
    height: 3rem;
}
.overfoot-right{
    height: 3rem;
    background-color: $primary-color;
}
#realfooter{
    background-color: $primary-color;
    color: white;
    padding-left: 2rem;
    padding-bottom: 6rem;
    font-size: 0.7rem;
}
#realfooter a{
    color: white;
}
.footerpicwrap{
    word-wrap:break-word;
    // background-color: white;
    color: $primary-color !important;
    padding: 0px!important;
}

.mobilerealfooter{
    background-color: $primary-color;
    color: white;
    font-size: 0.7rem;
    width: 98%;
}
.mobilerealfooter a{
    color: white;
}
.mfoot{
    padding: 2rem 2rem 3rem 2rem;
}
// .mobilesidenav{
//     width: 98%;
//     margin: 3rem 0 1rem 0;
// }
// .mobilesidenav a{
//     margin-left: 1rem;
// }
.icontext{
    color: $primary-color;
    float: right;
    position: relative;
    margin-right: 1rem;
    margin-bottom: 0.4rem;
    font-weight: bold;
    img {
        position: absolute;
        right: -0.5rem;
        top: 4px;
        height: 15px;
    }
    .icondesc {
        @media screen and (max-width: 920px) {
            display: none;
        }
    }
}
.mobilenav_footer-extra {
    margin-top: 2rem;

    a {
        margin-right: 1rem;
    }
    img {
        height: 3rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

/* INDEX.html */

#aktuellermonat{
    color: $primary-color;
}
@media screen and (min-width: 40em) {
    #kreis{
        // padding: 0 10rem 0 10rem;
    }
}
#monatswrapper{
    display: inline-block;
}
#moreuselesswraps{
    text-align: center;
}
#kreis{
    position: relative;
    max-width: 600px;
    margin: 0 auto;
}
#sinnspruch{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    width: 15rem;

    font-weight: bold;
    text-align: center;
}
#kreissvg{
    display: block;
    margin: 2rem auto 5rem auto;
}
#kreissvg path[onclick]{
    cursor: pointer;
}

#liste{
    display: flex;
    flex-direction: column;
}
.monatsitem{
    font-weight: bold;
}
.accordion-title, .accordion-content, :last-child:not(.is-active)>.accordion-title{
    border: none;
}
a.accordion-title{
    color: $primary-color;

    &:active, &:hover, &:focus {
        background-color: transparent;
    }
}
.accordion-content a{
    color: $primary-color;
}
div.accordion-content{
    margin: 1rem 1rem 1rem 3rem;
    padding: 0;
}
.accordion {
    margin-left: 2rem;
    .accordion-content {
        padding-left: 2rem;
        a {
            font-size: 0.875rem;
        }
        // the fix //
        margin-top: -2rem;
        // display: block;
        padding-left: 14rem;
        // plz take me out //
    }
    .accordion-item {
        a.accordion-title {
            font-size: 0.875rem;
        }
        &.is-active {
            a.accordion-title {
                color: #000;
                width: 52%;
                @media screen and (max-width: 1024px) {
                    width: 36%;
                }
                &:after{
                    content: '';
                    white-space: pre;
                    position: absolute;
                    bottom: 0.5rem;
                    height: 0.5rem;
                    left: 1rem;
                    width: 4.5rem;
                    background: $primary-color;
                    
                    /* optional animation */
                    -o-transition:.5s;
                    -ms-transition:.5s;
                    -moz-transition:.5s;
                    -webkit-transition:.5s;
                    transition:.5s;
                }
                &:before {
                    content: '';
                }
            }
        }
    }
}
:last-child > .accordion-content:last-child {
    border: none;
}
.upstring {
    border-top: 1px solid #0a0a0a;
    padding-left: 3rem;
    margin-left: -3rem;
    display: inline-block;
    margin-bottom: 0.8rem;
}

/* SUBPAGES */

h6.subtitle {
    @media screen and (max-width: 640px) {
        margin: 1rem 0;
    }    
}

.subtext{
    font-weight: normal;
}
.noaccordion{
    position: relative;
    padding-top: 3.5rem;
    margin-left: 3.5rem;
    .noaccordion-title {
        position: relative;
        display: block;
        margin-bottom: 1rem;
    }
    &.noheadspace{
        padding-top: 0px;
    }
    &.noheaderspaceing {
        .noaccordion-title.active {
            // margin-bottom: 0px;
        }
    }
}
.noaccordion-title.active {
    margin-bottom: 1rem;
    display: block;
}
a.active {
    color: $black;
    font-weight: 800;
}
#predigt{
    font-weight: normal;
    margin: 2rem 0 5rem 0;
}
#more, #download, #predigt-titel{
    margin-bottom: 2rem;
}

/* DAS PROJEKT */

    /* pls make style happen */

// Menu

.menu {
    a {
        // font-weight: 700;
    }
}

.space_top-5 {
    margin-top: 5rem;
}
.bottomline {
    border-bottom: 1px solid gray;
    margin-bottom: 1rem;
}