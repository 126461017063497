@font-face {
    font-family: 'opensans';
    src: url('/assets/fonts/opensans/opensans-regular-webfont.woff') format('woff'), 
        url('/assets/fonts/opensans/opensans-regular-webfont.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: 'opensans';
    src: url('/assets/fonts/opensans/opensans-bold-webfont.woff') format('woff'),
        url('/assets/fonts/opensans/opensans-bold-webfont.woff2') format('woff2');
    font-weight: 700;
}
@font-face {
    font-family: 'opensans';
    src: url('/assets/fonts/opensans/opensans-italic-webfont.woff') format('woff'),
        url('/assets/fonts/opensans/opensans-italic-webfont.woff2') format('woff2');
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: 'opensans';
    src: url('/assets/fonts/opensans/opensans-bolditalic-webfont.woff') format('woff'),
        url('/assets/fonts/opensans/opensans-bolditalic-webfont.woff2') format('woff2');
    font-style: italic;
    font-weight: 700;
}
@font-face {
    font-family: 'opensans';
    src: url('/assets/fonts/opensans/opensans-extrabold-webfont.woff') format('woff'),
        url('/assets/fonts/opensans/opensans-extrabold-webfont.woff2') format('woff2');
    font-weight: 900;
}
@font-face {
    font-family: 'opensans';
    src: url('/assets/fonts/opensans/opensans-extrabolditalic-webfont.woff') format('woff'),
        url('/assets/fonts/opensans/opensans-extrabolditalic-webfont.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'opensans';
    src: url('/assets/fonts/opensans/opensans-light-webfont.woff') format('woff'),
        url('/assets/fonts/opensans/opensans-light-webfont.woff2') format('woff2');
    font-weight: 100;
}
@font-face {
    font-family: 'opensans';
    src: url('/assets/fonts/opensans/opensans-lightitalic-webfont.woff') format('woff'),
        url('/assets/fonts/opensans/opensans-lightitalic-webfont.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'opensans';
    src: url('/assets/fonts/opensans/opensans-semibold-webfont.woff') format('woff'),
        url('/assets/fonts/opensans/opensans-semibold-webfont.woff2') format('woff2');
    font-weight: 800;
}
@font-face {
    font-family: 'opensans';
    src: url('/assets/fonts/opensans/opensans-semibolditalic-webfont.woff') format('woff'),
        url('/assets/fonts/opensans/opensans-semibolditalic-webfont.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
}